import { FormControl, ListItemIcon, ListItemText, ListSubheader, MenuItem, TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FourTiles, OneTile, ThreeTiles, TwoTiles } from "../../../enum/EInsertType";
import { useStoreState } from "../../../hooks";
import { IInsertType } from "../../../interfaces/IInsertType";
import { ReactSVG } from "react-svg";
import { FTSTheme } from "../../../App";
import { ABB } from "../../../constants/Theme";
import { ONE_BUTTON_ONE_ROCKER } from "../../../constants/InsertType";

interface IProps {
  selectedCoverNumber: number;
  insertTypes: IInsertType[];
  handleChangeTileType: (e: any) => Promise<void>;
}

const TileTypeSelect: React.FC<IProps> = ({ selectedCoverNumber, insertTypes, handleChangeTileType }) => {
  const theme = useTheme();
  const ftsTheme = useTheme() as FTSTheme;

  const { t } = useTranslation();
  const { userDesignData } = useStoreState((state) => state.designs.configuration);

  const color = ftsTheme.name == ABB ? "#696969" : theme.palette.primary.main;

  return (
    <>
      <FormControl
        sx={{
          m: 0,
          mt: 2,
          mb: 1,
          width: "100%",
          minWidth: "250px",
        }}
        className="insertConfiguration__layoutSelect"
      >
        <TextField
          select
          value={userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "center",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "center",
                horizontal: "center",
              },
              style: {
                maxHeight: "500px",
              },
            },
          }}
          onChange={(e) => {
            handleChangeTileType(e);
          }}
        >
          <ListSubheader
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#464646",
            }}
          >
            {t("insert_configuration.label.covers_with_1_tile")}
          </ListSubheader>
          {insertTypes
            .filter((o) => OneTile.map((x) => x.value).includes(o.name))
            .map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
              >
                <ListItemIcon>
                  {
                    <ReactSVG
                      src={
                        userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id ===
                        option.id
                          ? option.activeUrl
                          : option.disabledUrl
                      }
                      beforeInjection={(svg) => {
                        if (
                          userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id ===
                          option.id
                        ) {
                          svg.setAttribute("style", `fill: ${color}; stroke: ${color}`);
                        }
                      }}
                    />
                  }
                </ListItemIcon>
                <ListItemText className="insertConfiguration__text">
                  <p
                    className={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? "insertConfiguration__nameText insertConfiguration__nameText--selected"
                        : "insertConfiguration__nameText"
                    }
                  >
                    {t(OneTile.find((x) => x.value == option.name)!.key)}
                  </p>
                </ListItemText>
              </MenuItem>
            ))}
          <ListSubheader
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#464646",
            }}
          >
            {t("insert_configuration.label.covers_with_2_tiles")}
          </ListSubheader>
          {insertTypes
            .filter((o) => TwoTiles.map((x) => x.value).includes(o.name))
            .map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ReactSVG
                    src={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? option.activeUrl
                        : option.disabledUrl
                    }
                    beforeInjection={(svg) => {
                      let style = "";

                      if (
                        userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id ===
                        option.id
                      ) {
                        style += `fill: ${color}; stroke: ${color};`;
                      }

                      if (option.name === ONE_BUTTON_ONE_ROCKER) {
                        style += "transform: rotate(90deg);";
                      }

                      svg.setAttribute("style", style);
                    }}
                  />
                </ListItemIcon>
                <ListItemText className="insertConfiguration__text">
                  <p
                    className={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? "insertConfiguration__nameText insertConfiguration__nameText--selected"
                        : "insertConfiguration__nameText"
                    }
                  >
                    {t(TwoTiles.find((x) => x.value == option.name)!.key)}
                  </p>
                </ListItemText>
              </MenuItem>
            ))}
          <ListSubheader
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#464646",
            }}
          >
            {t("insert_configuration.label.covers_with_3_tiles")}
          </ListSubheader>
          {insertTypes
            .filter((o) => ThreeTiles.map((x) => x.value).includes(o.name))
            .map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ReactSVG
                    src={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? option.activeUrl
                        : option.disabledUrl
                    }
                    beforeInjection={(svg) => {
                      if (
                        userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id ===
                        option.id
                      ) {
                        svg.setAttribute("style", `fill: ${color}; stroke: ${color}`);
                      }
                    }}
                  />
                </ListItemIcon>
                <ListItemText className="insertConfiguration__text">
                  <p
                    className={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? "insertConfiguration__nameText insertConfiguration__nameText--selected"
                        : "insertConfiguration__nameText"
                    }
                  >
                    {t(ThreeTiles.find((x) => x.value == option.name)!.key)}
                  </p>
                </ListItemText>
              </MenuItem>
            ))}
          <ListSubheader
            sx={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#464646",
            }}
          >
            {t("insert_configuration.label.covers_with_4_tiles")}
          </ListSubheader>
          {insertTypes
            .filter((o) => FourTiles.map((x) => x.value).includes(o.name))
            .map((option) => (
              <MenuItem
                value={option.id}
                key={option.id}
                onClick={() => {}}
              >
                <ListItemIcon>
                  <ReactSVG
                    src={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? option.activeUrl
                        : option.disabledUrl
                    }
                    beforeInjection={(svg) => {
                      if (
                        userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id ===
                        option.id
                      ) {
                        svg.setAttribute("style", `fill: ${color}; stroke: ${color}`);
                      }
                    }}
                  />
                </ListItemIcon>
                <ListItemText className="insertConfiguration__text">
                  <p
                    className={
                      userDesignData?.userCustomDesign.insertDetails[selectedCoverNumber]?.insertType?.id === option.id
                        ? "insertConfiguration__nameText insertConfiguration__nameText--selected"
                        : "insertConfiguration__nameText"
                    }
                  >
                    {t(FourTiles.find((x) => x.value == option.name)!.key)}
                  </p>
                </ListItemText>
              </MenuItem>
            ))}
        </TextField>
      </FormControl>
    </>
  );
};

export default TileTypeSelect;
